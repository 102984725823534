import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentErrorHandlerDirective } from './component-error-handler.directive';

@NgModule({
    declarations: [ComponentErrorHandlerDirective],
    exports: [ComponentErrorHandlerDirective],
    imports: [CommonModule],
})
export class ComponentErrorHandlerModule {}
