import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MtNoteControlModule } from '@mt-ng2/note-control';
import { DynamicFormModule } from '@mt-ng2/dynamic-form';
import { EntityListModule } from '@mt-ng2/entity-list-module';
import { MtAlertsModule } from '@mt-ng2/alerts-module';

import { CommonNotesComponent } from './common-notes.component';
import { CommonNotesListComponent } from './common-notes-list/common-notes-list.component';
import { EntityComponentsNotesModuleConfig } from './libraries/module-config.library';

@NgModule({
    declarations: [CommonNotesComponent, CommonNotesListComponent],
    exports: [CommonNotesComponent, CommonNotesListComponent],
    imports: [CommonModule, NgbModule, RouterModule, DynamicFormModule, MtNoteControlModule, EntityListModule, MtAlertsModule],
    providers: [EntityComponentsNotesModuleConfig],
})
export class EntityComponentsNotesModule {}
