import { Injectable, Inject, Optional } from '@angular/core';

import { IEntityComponentsNotesModuleConfig, EntityComponentsNotesModuleConfigToken } from '@mt-ng2/entity-components-notes-config';

const defaultModuleConfig: IEntityComponentsNotesModuleConfig = {
    disableSort: false,
    max: 3,
};

@Injectable()
export class EntityComponentsNotesModuleConfig implements IEntityComponentsNotesModuleConfig {
    max: number;
    disableSort: boolean;

    constructor(@Inject(EntityComponentsNotesModuleConfigToken) @Optional() config: IEntityComponentsNotesModuleConfig) {
        Object.assign(this, defaultModuleConfig, config);
    }
}
